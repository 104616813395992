const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt"},
        {name:"Home",link:"BoHome",icon:"fas fa-home"},
        // {name:"Home Page",link:"BoHome",icon:"fas fa-home"},
        {name:"Products",divider:'Products',icon:'',link:''},
        {name:"Products", icon:"fas fa-cube",childs:[
            {name:"Main Page",link:"BoProductCat"},
            {name:"Product",link:"BoProduct"},
        ]},
        {name:"Others",divider:'Others',icon:'',link:''},
        {name:"Health Info", icon:"fas fa-list-alt",childs:[
            {name:"Article",link:"BoTopic"},
            {name:"Category",link:"BoTopicCat"},
        ]},
        {name:"Recipes", icon:"fas fa-paste",childs:[
            {name:"Recipe",link:"BoRecipe"},
            {name:"Recipe Category",link:"BoRecipeCat"},
        ]},
        {name:"Events",link:"BoEvent",icon:"fas fa-newspaper"},
        {name:"Ask the Doctor",link:"BoAskedDoctor",icon:"fas fa-stethoscope"},        
        {name:"Mini Health", icon:"fas fa-paste",childs:[
            {name:"Food Meter",link:"BoFoodMeter"},
            {name:"Prediabetes",link:"BoPradiabetes"},
            {name:"Heart Disease",link:"BoHeartDisease"},
            {name:"Activity Counter",link:"BoActivityCounter"},
            {name:"BMI Result",link:"BoBmi"},
        ]},
        {name:"Settings",divider:'Settings',icon:'',link:''},
        {name:"Settings", icon:"fas fa-cog",childs:[
            {name:"Static SEO",link:"StaticSeo"},
            {name:"Static Content",link:"StaticContent"},
            {name:"Template",link:"MrTemplate"},
            {name:"Web Settings",link:"AppWebSettings"},
        ]},
        {name:"User Management", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig