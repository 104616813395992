<script>
import BaseVue from '@frontend/Base.vue';
import Gen from '../helper/Gen';

export default {
	extends: BaseVue,
	data() {
		return {
			product: false,
			category: {},
		}
	},
	computed: {
		mySlug() {
			return this.$route.params.slug
		}
	},
	async mounted() {
		this.$set(this.$root, 'page', this)
		this.getProduct()
		setTimeout(()=>{ $("a[href$='/produk']").closest("li").addClass("active") }, 300)
	},
	methods: {
		getProduct() {
			this.product = false
			let params = Gen.clone(this.$route.query)
			Gen.apirest("/product-list/" + this.mySlug, params, (err, resp) => {
				if (err) console.log(err)
				if (resp.code == 404) return this.$router.push({name:'404'})
				this.category = resp.category
				this.product = resp.data
				setTimeout(()=>{
					global.SEMICOLON.initialize.fullScreen()
					$("#FoMain #wrapper > *:not(#header)").css("opacity", 1)
					Gen.loadScript("/frontend/js/custom.js", false)
				}, 100)
			})
		},
		changePage(page=1){
			this.$router.push({query:Object.assign(Gen.clone(this.$route.query),{page:page})})
		},
		removeTop(){
			document.querySelector("#slider.half-slider .slider-caption").removeAttribute("style");
		}
	},
	watch:{
		$route(){
			this.getProduct()
			this.removeTop()
		}
	}
}
</script>
<template>
	<div>
		<section id="slider" class="slider-element half-slider  swiper_wrapper force-full-screen full-screen clearfix" data-loop="true"
			data-autoplay="5000">
			<div class="force-full-screen full-screen"
				:style="'background-image: url('+uploader(category.mpc_hero_back_image)+');background-position: center;background-size:cover;'">
				<div class="container clearfix d-flex d-lg-block align-items-center">
					<div class="row align-items-center align-items-lg-start py-lg-4">
						<div class="col-lg-6">
							<div class="slider-caption t0" :class="isTablet || isMobile ? 'slider-caption-center':''">
								<h2 data-animate="fadeInDown">{{category.mpc_hero_title}}</h2>
								<p data-animate="fadeInUp" data-delay="400" class="d-none d-lg-block">{{category.mpc_hero_sub_title}}</p>
							</div>
						</div>
						<div class="col-lg-6 order-1 order-md-0 text-center text-lg-left banner-hero-img">
							<img :src="uploader(category.mpc_hero_image)" :alt="category.mpc_hero_title">
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="content">
			<div class="content-wrap">
				<section class="section nobg nomargin">
					<div class="container">
						<div class="prod-list" v-if="product&&product.data.length">
							<div class="row justify-content-center">
								<div v-for="(v,k) in product.data" :key="k" class="col-md-3 col-6" data-aos="fade-up" :data-aos-delay="k*50">
									<router-link :to="{name:'ProductsDetail', params:{slug:v.mp_slug}}" class="prod-item">
										<img :src="uploader(v.mp_image)" :alt="v.mp_title" :title="v.mp_title">
										<h4>{{v.mp_title}}</h4>
									</router-link>
								</div>
							</div>
						</div>
						<div v-if="product!=false&&!product.data.length" class="center">
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-4">
                                    <img :src="assets('fo_images', 'empty.svg')" alt="No Results Found">
                                </div>
                            </div>
                            <h3>Belum ada produk dikategori ini.</h3>
                        </div>
						<Pagination v-if="product" :limit="2" :data="product" @pagination-change-page="changePage" class="pagination pagination-transparent pagination-circle justify-content-center mb-0 mt-3 mt-md-4">
							<i slot="prev-nav" class="icon-angle-left"></i>
							<i slot="next-nav" class="icon-angle-right"></i>
						</Pagination>
					</div>
				</section>
			</div>
		</section>
	</div>
</template>